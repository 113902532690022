   (function($){
        $(function(){
           $( "#robot" ).autocomplete({
                source: "/suche/searchAll",
                minLength: 1,
                select: function( event, ui ) {
                    window.location = ("/roboter/" + ui.item.url + "/" + ui.item.id + "/")
                }
            });

            $('.button-collapse').sideNav();
            $('.dropdown-button').dropdown();
            $('select').material_select();
            $('.collapsible').collapsible({
                accordion : false
            });

            $('[data-url-follow]').click(function () {
                location.href=$(this).attr("data-url-follow")
            });

            /*$('.slider-logos').slick({
                centerMode: true,
                centerPadding: '60px',
                slidesToShow: 3,
                slidesToScroll: 3,
                variableWidth: true,
                autoplay: true,
                autoplaySpeed: 2500,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 1
                        }
                    }
                ]
            });*/

        }); // end of document ready
    })(jQuery); // end of jQuery name space